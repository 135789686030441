import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpHeaders } from '@angular/common/http';

import { BehaviorSubject, of, Subject } from 'rxjs';

import { Apollo } from 'apollo-angular';
import { RetryLink } from '@apollo/client/link/retry';
import { setContext } from '@apollo/client/link/context';
import { ApolloLink, InMemoryCache } from '@apollo/client/core';
import { HttpLink, HttpBatchLink } from 'apollo-angular/http';
import { onError } from '@apollo/client/link/error';

import { environment } from './../environments/environment';
import { AuthService, AuthError } from './auth.service';
import { throttleTime, distinctUntilChanged, filter, first, map } from 'rxjs/operators';
import { LoggingService } from './logging.service';
import { SentryService } from './sentry.service';
import { SentryErrorHandler } from './sentry-error-handler.service';
import { UIService } from './ui.service';
import { MessageService } from 'primeng/api';
import { Operation } from '@apollo/client/core';

const SERVER_RETRIES =
  environment.retryConfig && typeof environment.retryConfig.serverErrorRetries === 'number'
    ? environment.retryConfig.serverErrorRetries
    : 2;
const OTHER_RETRIES =
  environment.retryConfig && typeof environment.retryConfig.otherErrorRetries === 'number' ? environment.retryConfig.otherErrorRetries : 1;
const THROTTLE_RETRIES =
  environment.retryConfig && typeof environment.retryConfig.throttleErrorRetries === 'number'
    ? environment.retryConfig.throttleErrorRetries
    : 1;
const MAX_RETRIES =
  environment.retryConfig && typeof environment.retryConfig.maxErrorRetries === 'number' ? environment.retryConfig.maxErrorRetries : 20;
const INITIAL_RETRY_DELAY =
  environment.retryConfig && typeof environment.retryConfig.initialDelay === 'number' ? environment.retryConfig.initialDelay : 500;
const MAX_RETRY_DELAY =
  environment.retryConfig && typeof environment.retryConfig.maxDelay === 'number' ? environment.retryConfig.maxDelay : 4000;
const MAX_THROTTLE_RETRY_DELAY =
  environment.retryConfig && typeof environment.retryConfig.maxThrottleDelay === 'number' ? environment.retryConfig.maxThrottleDelay : 4000;

export enum NetworkErrorCode {
  UNAUTHENTICATED = 'unauthenticated',
  FORBIDDEN = 'forbidden',
  PASSWORD_EXPIRED = 'password-expired',
  DEPRECATED_CLAIMS = 'deprecated-claims',
  BAD_REQUEST = 'bad-request',
  OTHER = 'other',
}

export interface ILinkContext {
  /** E.g. [{ errorCode: 'BAD_USER_INPUT' }] to ignore GraphQL errors of type BAD_USER_INPUT, should be set in link.mutate or link.query's context
   * there is an optional errorMessage which will only filter out error with a specific message
   */
  ignoreErrors?: Array<{ errorCode: string; errorMessage?: string | RegExp }>;
  /** if set to true errors will be swallowed, used by the retryLink */
  retryingOperation?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class LinkService {
  private maxServerRetries = SERVER_RETRIES;
  private maxOtherRetries = OTHER_RETRIES;
  private $currentRegion = new BehaviorSubject<string>('');
  private $currentHotel = new BehaviorSubject<string>('none');

  private $retryAttempts = new Subject<{ count: number; code?: number; type: string }>();
  private $graphQLErrors = new Subject<any>();
  private $networkErrors = new Subject<{ code: NetworkErrorCode; message: string; error; statusCode; errorCount: number }>();
  private $retryFailures = new Subject<{
    message: string;
    statusCode?: number;
    count: number;
    type: 'auth' | 'http' | 'network' | 'other' | 'invalid' | 'forbidden' | 'unknown';
    error;
  }>();
  private errorCount = {};
  private errorQueueBeginTimes: Record<string, number> = {};
  private errorQueues: Record<string, boolean[]> = {};

  private apolloLinks: Record<string, boolean> = {};

  constructor(
    private log: LoggingService,
    private apollo: Apollo,
    private authService: AuthService,
    private sentryService: SentryService,
    private httpBatchLink: HttpBatchLink,
    private uiService: UIService,
    private messageService: MessageService,
    private httpLink: HttpLink
  ) {
    this.authService
      .isAuthenticated()
      .pipe(
        distinctUntilChanged(),
        filter((loggedIn) => !loggedIn)
      )
      .subscribe(async () => {
        // clean the cache and the links if no one is logged in
        const resetPromises = Object.keys(this.apolloLinks)
          .filter((link) => link !== 'unauth')
          .map(async (link) => {
            this.apolloLinks[link] = false;
            const client = this.apollo.use(link)?.client;
            if (client) {
              client.stop();
              await client.clearStore();
            }
            this.apollo.removeClient(link);
          });
        await Promise.all(resetPromises);
      });
    this.$retryFailures
      .pipe(
        filter((data) => !!data),
        throttleTime(5000),
        distinctUntilChanged((a, b) => a.statusCode === b.statusCode && a.type === b.type && a.count === b.count)
      )
      .subscribe((data) => {
        const { type, statusCode, ...rest } = data;
        if (rest.error) {
          rest['errorMessage'] = rest.error.message;
          rest['errorStack'] = rest.error.stack;
          const error = {
            message: rest.error.message,
            stack: rest.error.stack,
          };
          if (type === 'auth') {
            this.sentryService.sendMessage('Unauthenticated user access', 'info', {
              ...rest,
              error,
              message: `Unauthenticated user access [retryFailures] "${rest.message}"`,
              type,
              statusCode,
            });
            this.messageService.clear('network');
            this.uiService.confirmActionDanger(
              `Unable to access backend data, since you are not authenticated. Please log in again.`,
              'Login',
              () => this.authService.signOut(),
              undefined,
              'Unauthenticated'
            );
          } else if (type === 'forbidden') {
            this.sentryService.sendMessage('Forbidden user access', 'warning', {
              ...rest,
              error,
              message: `Forbidden user access [retryFailures] "${rest.message}"`,
              type,
              statusCode,
            });
            this.messageService.clear('network');
            this.uiService.acknowledgeError('You are not authorized to do this action.', undefined, 'Network Warning');
          } else if (type === 'network') {
            this.sentryService.sendError(
              error,
              {
                ...rest,
                message: `Unable to connect to the API [retryFailures] "${rest.message}"`,
                type,
                statusCode,
              },
              'network-failure'
            );
            this.messageService.clear('network');
            this.uiService.confirmActionDanger(
              statusCode === 429
                ? 'Too many requests being sent to the server. Please wait 1 minute and then reload.'
                : `Unable to access server. Please check your network connection and Refresh. [ERROR CODE: ${statusCode}].`,
              'Refresh',
              () => window.location.reload(),
              undefined,
              'Network Error',
              'Wait'
            );
          } else if (type === 'other') {
            this.sentryService.sendError(
              error,
              {
                ...rest,
                message: `Unable to connect to the API [retryFailures] "${rest.message}"`,
                type,
                statusCode,
              },
              'network-failure'
            );
            this.messageService.clear('network');
            this.uiService.confirmActionDanger(
              `Unable to access the server, please check your internet connection. Once your internet connection is working reload. If the error still continues contact an administrator.`,
              'Reload',
              () => window.location.reload(),
              undefined,
              'Connection Error'
            );
          } else if (type === 'http') {
            this.messageService.clear('network');
            this.uiService.confirmActionDanger(
              `Unable to connect to the server, please check your internet connection. Once your internet connection is working reload. If the error still continues contact an administrator.`,
              'Reload',
              () => window.location.reload(),
              undefined,
              'HTTP Error'
            );
          } else if (type === 'invalid') {
            this.sentryService.sendError(
              error,
              {
                ...rest,
                message: `Invalid request sent to the API [retryFailures] "${rest.message}"`,
                type,
                statusCode,
              },
              'network-failure'
            );
            this.messageService.clear('network');
            this.uiService.confirmActionDanger(
              `Invalid request sent to the server, please check your internet connection. Once your internet connection is working you can reload and if the error continues contact an administrator.`,
              'Reload',
              () => window.location.reload(),
              undefined,
              'Invalid Request'
            );
          } else {
            this.sentryService.sendMessage('Unknown link error [retryFailures]', 'warning', {
              ...rest,
              type,
              statusCode,
            });
            this.messageService.clear('network');
            this.uiService.acknowledgeError('An unknown error has occurred please contact an administrator.', undefined, 'Unknown error');
          }
        }
      });

    this.$networkErrors
      .pipe(
        distinctUntilChanged((a, b) => a?.code === b?.code && a?.statusCode === b?.statusCode),
        throttleTime(30000)
      )
      .subscribe((data) => {
        this.sentryService.sendMessage('Network errors', 'warning', data);
      });

    this.$retryAttempts.pipe(throttleTime(5000)).subscribe(() => {
      this.messageService.clear('network');
      this.messageService.add({
        key: 'network',
        severity: 'warn',
        life: 4000,
        icon: 'pi-spin pi-spinner',
        summary: 'Communication error',
        detail: 'Retrying...',
      });
    });
  }

  private getGQLLink(region: string, batched: boolean, useAuthenticatedLink = false) {
    const extra = batched ? 'batch' : 'std';
    const linkName = `${region}-link-${extra}-${useAuthenticatedLink}`;
    if (!this.apolloLinks[linkName]) {
      this.log.debug(`create GQLLink ${linkName}`);
      if (batched) {
        this.apollo.create(this.apolloBatchLinkFactory(region), linkName);
      } else {
        const authLink = useAuthenticatedLink ? this.authenticatedLinkFactory() : this.authorizedLinkFactory();
        this.apollo.create(this.apolloLinkFactory(authLink, region), linkName);
      }
      this.log.debug('created GQLLink ' + linkName);
      this.apolloLinks[linkName] = true;
    }
    return this.apollo.use(linkName);
  }

  GQLCentral(batched = true, useAuthenticatedLink = false) {
    return this.getGQLLink('default', batched, useAuthenticatedLink);
  }

  GQLUnauth() {
    let uri: string;
    if (environment.graphQLLocalCoreUrl) {
      uri = environment.graphQLLocalCoreUrl + '/graphql';
    } else {
      uri = environment.graphQLUrl + '/graphql';
    }
    if (!this.apolloLinks['unauth']) {
      this.log.debug(`create GQLLink unauth`);
      const retryLink = new RetryLink({
        delay: {
          initial: 1000,
          max: MAX_THROTTLE_RETRY_DELAY,
          jitter: true,
        },
        attempts: (count, operation, error) => {
          if (operation?.operationName === 'isAlive') {
            return false;
          }
          return count <= 2;
        },
      });
      this.apollo.create(
        {
          link: retryLink.concat(
            this.httpLink.create({
              uri,
              headers: new HttpHeaders({ authorization: `Bearer app:${environment.firebaseConfig.appId}` }),
            })
          ),
          cache: new InMemoryCache({
            resultCaching: true,
          }),
        },
        'unauth'
      );
      this.log.debug('created GQLLink unauth');
      this.apolloLinks['unauth'] = true;
    }
    return this.apollo.use('unauth');
  }

  GQLForRegion(regionOverride: string, batched = true) {
    return this.getGQLLink(regionOverride, batched);
  }

  GQLRegion(batched = true) {
    return this.$currentRegion.pipe(
      // if there is no region set then wait for the region to be set
      filter((region) => !!region),
      map((region) => this.getGQLLink(region, batched))
    );
  }

  setCurrentRegion(regCode: string): void {
    this.log.info('current region set link to ' + regCode);
    this.$currentRegion.next(regCode);
  }

  getCurrentRegion() {
    return this.$currentRegion.value;
  }

  setCurrentHotel(hotelId?: string): void {
    this.$currentHotel.next(hotelId || 'none');
  }

  getCurrentHotelId() {
    return this.$currentHotel.value;
  }

  getCurrentHotelW() {
    return this.$currentHotel;
  }

  awaitCurrentHotel(hotelIdin: string) {
    let hotelS = hotelIdin ? of(hotelIdin) : this.$currentHotel;
    return hotelS.pipe(
      filter((hid) => hid != 'none'),
      first()
    );
  }

  private authenticatedLinkFactory() {
    const authLink = setContext((op, { headers }) => {
      return new Promise((resolve, reject) => {
        this.authService
          .getAuthenticationToken()
          .pipe(
            filter((val) => !!val),
            first()
          )
          .subscribe(
            ({ token, source }) => {
              const authorization = `Bearer ${token}`;
              const httpHeaders = new HttpHeaders({
                ...(headers || {}),
                authorization,
                'trace-id': this.sentryService.getTraceId(),
                'X-auth-source': source,
              });
              resolve({ headers: httpHeaders });
            },
            (error) => reject(error)
          );
      });
    });
    return authLink;
  }

  private authorizedLinkFactory() {
    const authLink = setContext((op, { headers }) => {
      return new Promise((resolve, reject) => {
        this.authService
          .getAuthorizationToken()
          .pipe(
            filter((val) => !!val),
            first()
          )
          .subscribe(
            (token) => {
              const httpHeaders = new HttpHeaders({
                ...(headers || {}),
                authorization: `Bearer ${token}`,
                'trace-id': this.sentryService.getTraceId(),
                'X-auth-source': 'wot',
              });
              resolve({ headers: httpHeaders });
            },
            (error) => reject(error)
          );
      });
    });
    return authLink;
  }

  private apolloBaseLinkFactory(httpLink: ApolloLink, authLink: ApolloLink) {
    // we check that all the headers exist and will be accepted using the 'reduce'
    const getSafeHeader = (obj) =>
      Object.entries(obj).reduce((prev, [key, val]) => {
        if ((val as any)?.length >= 0) {
          prev[key] = val;
        }
        return prev;
      }, {});

    const getLinkContext = (operation: Operation) => (operation.getContext() || {}) as ILinkContext;

    const updateLinkContext = (operation: Operation, context: ILinkContext) => {
      const oldContext = operation.getContext() || {};
      operation.setContext({ ...oldContext, ...context });
    };

    /**
     * Build a window with *windowSize* number of frames of length *frameInterval*, each frame is set to true if an error occurs in that frame.
     * If at least *maxErrorsInWindow* frames are true that means that we've had errors happening in too many frames in the
     * window and then we return false
     * @param now - timestamp of the error happening
     * @param errorType
     * @param maxErrorsInWindow defaults to 3
     * @param windowSize if not specified uses *MAX_RETRY_DELAY* to calculate the window size
     * @param frameInterval defaults to *INITIAL_RETRY_DELAY* * 1.5
     * @returns true if insufficient errors in the intervals else false
     */
    const windowFilter = (
      now: number,
      errorType: string,
      maxErrorsInWindow = 3,
      windowSize?: number,
      frameInterval = INITIAL_RETRY_DELAY * 1.5
    ) => {
      const maxFrames = windowSize || Math.ceil(((maxErrorsInWindow + 1) * MAX_RETRY_DELAY) / (frameInterval || 1));
      let begin = this.errorQueueBeginTimes[errorType];
      let errorQueue = this.errorQueues[errorType] || [];
      if (errorQueue.length < maxFrames) {
        errorQueue.push(...Array(maxFrames - errorQueue.length).fill(false));
      } else if (errorQueue.length > maxFrames) {
        errorQueue = errorQueue.slice(0, maxFrames);
      }
      if (!begin) {
        begin = now;
      }
      // calculate how it has been since the beginning of the current error queue
      const diff = now - begin;
      // calculate how many frames it has been since the beginning of the currnt error queue
      const frame = Math.floor(diff / (frameInterval || 1));
      if (frame > maxFrames) {
        // 'now' is past the current queue so check how far we have overshot
        const overshoot = frame - maxFrames;
        if (overshoot >= maxFrames) {
          errorQueue = Array(maxFrames).fill(false);
          errorQueue[0] = true;
          begin = now;
        } else {
          const overshootArray = Array(overshoot).fill(false);
          overshootArray[overshootArray.length - 1] = true;
          errorQueue = [...errorQueue.slice(overshoot), ...overshootArray];
          begin += overshoot * (frameInterval || 1);
        }
      } else {
        errorQueue[frame] = true;
      }
      this.errorQueues[errorType] = errorQueue;
      this.errorQueueBeginTimes[errorType] = begin;
      const hasErrorQueueMaxedOut = this.errorQueues[errorType]?.filter((e) => e).length >= maxErrorsInWindow;
      if (hasErrorQueueMaxedOut) {
        return false;
      } else {
        return true;
      }
    };

    const retryLink = new RetryLink({
      delay: (count, operation, error) => {
        const expBackoff = () => Math.pow(2, count - 1) + (Math.pow(2, count + 1) - Math.pow(2, count - 1)) * Math.random();
        const getDelay = (initialDelay) => (count > 0 ? expBackoff() : 2 * Math.random()) * initialDelay;
        const status = error?.statusCode || error?.status;
        if (Number(status) === 429) {
          // for network throttling our initial delay is at least 1 second
          const minThrottleDelay = 1000;
          const throttleDelay = getDelay(minThrottleDelay);
          return throttleDelay < MAX_THROTTLE_RETRY_DELAY
            ? throttleDelay
            : MAX_THROTTLE_RETRY_DELAY + minThrottleDelay * (Math.random() - 1) * 2;
        }
        const normalDelay = getDelay(INITIAL_RETRY_DELAY);
        return normalDelay < MAX_RETRY_DELAY ? normalDelay : MAX_RETRY_DELAY + INITIAL_RETRY_DELAY * (Math.random() - 0.5) * 2;
      },
      attempts: (count, operation, error) => {
        if (count > MAX_RETRIES) {
          this.sentryService.sendMessage(`retry link count exceeded for ${operation?.operationName} ${count} > ${MAX_RETRIES}`, 'warning', {
            error: error.message,
            errorStack: error.stack,
            count,
            MAX_RETRIES,
            operationName: operation?.operationName,
          });
          return false;
        }
        const now = Date.now();
        const checkOtherRetries = () => windowFilter(now, 'other', OTHER_RETRIES);
        const checkServerRetries = () => windowFilter(now, 'server', SERVER_RETRIES);
        const checkThrottleRetries = () => windowFilter(now, 'throttle', THROTTLE_RETRIES);
        const errors = (error as any)?.error?.errors;
        if (errors && errors.some((e) => e.message && (e.message as String).endsWith('Password has expired'))) {
          this.log.info('expired password so we ignore and do not retry');
          return false;
        }
        if (errors && errors.some((e) => e.message && (e.message as String).endsWith('Deprecated claims version'))) {
          this.log.info('deprecated claims version so we ignore and do not retry');
          return false;
        }
        const status = error.statusCode || error.status;
        if (status) {
          if (Number(status) === 500) {
            this.log.info(`[Retry Attempt] status=${status} count=${count} max=${this.maxServerRetries}`);
            const retry = checkServerRetries();
            if (retry) {
              this.$retryAttempts.next({ count, code: Number(status), type: 'network' });
              updateLinkContext(operation, { retryingOperation: true });
              return true;
            }
            this.$retryFailures.next({
              message: 'Unable to recover from internal server error',
              statusCode: status,
              count,
              type: 'network',
              error,
            });
            return false;
          } else if (Number(status) === 429) {
            this.log.info(`[Retry Attempt] status=${status} count=${count} max=${this.maxServerRetries}`);
            const retry = checkThrottleRetries();
            if (retry) {
              this.$retryAttempts.next({ count, code: Number(status), type: 'network' });
              updateLinkContext(operation, { retryingOperation: true });
              return true;
            }
            this.$retryFailures.next({
              message: 'Unable to recover from network throttling error',
              statusCode: status,
              count,
              type: 'network',
              error,
            });
            return false;
          } else if (Number(status) === 400) {
            this.$retryFailures.next({
              message: 'Bad request',
              statusCode: status,
              count,
              type: 'invalid',
              error,
            });
            return false;
          } else if (Number(status) === 401) {
            this.$retryFailures.next({
              message: 'Unauthenticated user',
              statusCode: status,
              count,
              type: 'auth',
              error,
            });
            return false;
          } else if (Number(status) === 403) {
            this.$retryFailures.next({
              message: 'User does not have appropriate access rights - 403',
              statusCode: status,
              count,
              type: 'forbidden',
              error,
            });
            return false;
          }
          this.log.info(`[Retry Attempt] status=${status} count=${count} max=${this.maxOtherRetries}`);
          const retry = checkOtherRetries();
          if (retry) {
            this.$retryAttempts.next({ count, code: Number(status), type: 'network' });
            updateLinkContext(operation, { retryingOperation: true });
            return true;
          }
          this.$retryFailures.next({ message: `Unable to recover from API error`, statusCode: status, count, type: 'network', error });
          return false;
        }

        if (error instanceof AuthError) {
          this.log.info(`[Retry Attempt] ${error.message}`);
          const retry = checkOtherRetries();
          if (retry) {
            this.$retryAttempts.next({ count, type: 'auth' });
            updateLinkContext(operation, { retryingOperation: true });
            return true;
          }
          this.$retryFailures.next({ message: 'Unable to recover from auth error', type: 'auth', count, error });
          return false;
        }
        if (error instanceof HttpErrorResponse) {
          this.log.info(`[Retry Attempt] ${error.message}`);
          const retry = checkOtherRetries();
          if (retry) {
            this.$retryAttempts.next({ count, type: 'http' });
            updateLinkContext(operation, { retryingOperation: true });
            return true;
          }
          this.$retryFailures.next({ message: 'Unable to recover from HTTP error', type: 'http', count, error });
          return false;
        }
        if (
          error instanceof TypeError &&
          error.message.includes("'length'") &&
          error.message.includes('Cannot read') &&
          error.message.includes('null')
        ) {
          const retry = checkOtherRetries();
          this.log.info(`[Retry Attempt] ${error.message}`);
          if (retry) {
            this.$retryAttempts.next({ count, type: 'other' });
            updateLinkContext(operation, { retryingOperation: true });
            return true;
          }
          this.$retryFailures.next({ message: 'Unable to recover from length TypeError error', type: 'other', count, error });
          return false;
        }
        this.log.info(`[Retry Attempt] Unknown Error count=${count} ${JSON.stringify(error)}`);
        this.$retryFailures.next({
          message: `Unable to recover from Unknown error`,
          statusCode: undefined,
          count: undefined,
          type: 'unknown',
          error,
        });
        return false;
      },
    });

    const errorLink = onError((errorDetails) => {
      if (!errorDetails) {
        this.sentryService.sendMessage('Unable to determine error Details', 'warning');
        return;
      }
      const { graphQLErrors: rawGraphQLErrors, networkError, response, operation } = errorDetails;
      const { retryingOperation, ignoreErrors } = getLinkContext(operation);
      if (retryingOperation) {
        if (response?.errors) {
          response.errors = null;
        }
        this.log.debug(`[retrying] error: ${JSON.stringify(rawGraphQLErrors || networkError)}`);
        return;
      }

      // on the operation request we can include a list of errors to ignore in the context, such as ['BAD_USER_INPUT']
      // then the error needs to be handled in the subscription
      const graphQLErrors = ignoreErrors?.length
        ? rawGraphQLErrors.filter(
            (err: any) =>
              !ignoreErrors.some(
                ({ errorCode, errorMessage }) =>
                  errorCode === err.extensions?.code &&
                  (errorMessage === undefined ||
                    (errorMessage instanceof RegExp ? errorMessage.test(err.message) : err.message === errorMessage))
              )
          )
        : rawGraphQLErrors;

      if (graphQLErrors) {
        graphQLErrors.forEach((err) => this.$graphQLErrors.next(err));
        graphQLErrors.map(({ message, locations, path }) =>
          this.log.info(`[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(locations)}, Path: ${path}`)
        );
      }
      if (networkError) {
        const statusCode = (networkError as any).statusCode || (networkError as any).status || -1;
        this.errorCount[statusCode] = this.errorCount[statusCode] ? this.errorCount[statusCode] + 1 : 1;
        const errors = (networkError as any).error && (networkError as any).error.errors;
        if (statusCode === 400) {
          if (errors && errors.length > 0 && errors.some((e) => e.extensions && e.extensions.code === 'UNAUTHENTICATED')) {
            if (errors.some((e) => e.message && (e.message as String).endsWith('Password has expired'))) {
              this.$networkErrors.next({
                code: NetworkErrorCode.PASSWORD_EXPIRED,
                message: `User's login has been expired and needs to be reset`,
                error: networkError,
                statusCode,
                errorCount: this.errorCount[statusCode],
              });
              // we've reported the error (this.$networkErrors) and need to stop any retries so clear the error
              if (response) {
                response.errors = null;
              }
            } else if (errors.some((e) => e.message && (e.message as String).endsWith('Deprecated claims version'))) {
              this.$networkErrors.next({
                code: NetworkErrorCode.DEPRECATED_CLAIMS,
                message: `User's claims are old and user needs to log in again`,
                error: networkError,
                statusCode,
                errorCount: this.errorCount[statusCode],
              });
              // we've reported the error (this.$networkErrors) and need to stop any retries so clear the error
              if (response) {
                response.errors = null;
              }
            } else {
              this.$networkErrors.next({
                code: NetworkErrorCode.UNAUTHENTICATED,
                message: 'User does not have appropriate access rights - unauthenticated',
                error: networkError,
                statusCode,
                errorCount: this.errorCount[statusCode],
              });
            }
          } else {
            this.$networkErrors.next({
              code: NetworkErrorCode.BAD_REQUEST,
              message: 'Unable to process request',
              error: networkError,
              statusCode,
              errorCount: this.errorCount[statusCode],
            });
          }
        } else if (statusCode === 401) {
          this.$networkErrors.next({
            code: NetworkErrorCode.UNAUTHENTICATED,
            message: 'User is not authenticated',
            error: networkError,
            statusCode,
            errorCount: this.errorCount[statusCode],
          });
        } else if (statusCode === 403) {
          this.$networkErrors.next({
            code: NetworkErrorCode.FORBIDDEN,
            message: 'User does not have appropriate access rights - forbidden',
            error: networkError,
            statusCode,
            errorCount: this.errorCount[statusCode],
          });
        } else if (this.errorCount[statusCode] > this.maxOtherRetries) {
          this.$networkErrors.next({
            code: NetworkErrorCode.OTHER,
            message: networkError?.message,
            error: networkError,
            statusCode,
            errorCount: this.errorCount[statusCode],
          });
        }
        this.log.info(`[Network error]: ${JSON.stringify(networkError)}`);
      }
      if (rawGraphQLErrors?.length && !graphQLErrors?.length && !networkError) {
        // we are ignoring all the graphQLerrors so we clear the errors
        if (response) {
          response.errors = null;
        }
      }
    });

    const sentryApiLink = SentryErrorHandler.apiLink();

    const activeLink = new ApolloLink((operation, forward) => {
      if (operation?.query) {
        if (operation.query?.definitions.some((def) => (def as any)?.operation === 'mutation')) {
          // if we do a mutation then we assume the user is still active
          this.authService.markActive();
        }
      }
      return forward(operation);
    });

    //compose these links toegther
    return {
      link: activeLink.concat(sentryApiLink.concat(retryLink.concat(authLink.concat(errorLink.concat(httpLink))))),
      cache: new InMemoryCache({
        resultCaching: true,
        typePolicies: {
          SchedulerDepartmentFull: {
            keyFields: ['departmentName', 'hotelId', 'outletIndex', 'outletType', 'subDeptName'],
          },
        },
      }),
    };
  }

  private apolloLinkFactory(authLink: ApolloLink, region: string) {
    this.log.debug('creating a link - APOLLOLINKFACTORY ' + region);
    let uri: string;
    if (region !== 'default') {
      if (environment.graphQLLocalRegionUrl) {
        uri = environment.graphQLLocalRegionUrl + '/graphql';
      } else {
        uri = environment.graphQLUrl.replace('core', region.toLowerCase()) + '/graphql';
      }
    } else {
      if (environment.graphQLLocalCoreUrl) {
        uri = environment.graphQLLocalCoreUrl + '/graphql';
      } else {
        uri = environment.graphQLUrl + '/graphql';
      }
    }
    const http = this.httpLink.create({ uri });
    return this.apolloBaseLinkFactory(http, authLink);
  }

  private apolloBatchLinkFactory(region: string, batchInterval = 10, batchMax = 50) {
    this.log.debug('creating a link - APOLLOLINKFACTORY ' + region);
    let uri;
    if (region !== 'default') {
      if (environment.graphQLLocalRegionUrl) {
        uri = environment.graphQLLocalRegionUrl + '/graphql';
      } else {
        uri = environment.graphQLUrl.replace('core', region.toLowerCase()) + '/graphql';
      }
    } else {
      if (environment.graphQLLocalCoreUrl) {
        uri = environment.graphQLLocalCoreUrl + '/graphql';
      } else {
        uri = environment.graphQLUrl + '/graphql';
      }
    }
    this.log.debug('make link to ', uri);

    const http = this.httpBatchLink.create({ uri, batchInterval, batchMax });
    return this.apolloBaseLinkFactory(http, this.authorizedLinkFactory());
  }

  public networkErrors() {
    return this.$networkErrors.asObservable();
  }
}
